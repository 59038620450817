import { useTableIdFromUrl } from "hooks/localstate/url/useTableIdFromUrl"
import { useMemo } from "react"
import { SelfOrderOption } from "types/firestore/distributionOption"
import { useActiveDistributionOptions } from "../advanced/useActiveDistributionOptions"
import { WithRef } from "../FirestoreDocument"

/**
 * Returns the distribution option for the tableId of the URL.
 */
export function useTableIdDistOption() {
  const tableId = useTableIdFromUrl()

  const distOptions = useActiveDistributionOptions()

  const distOption = useMemo(() => {
    return distOptions.find(
      option => !!tableId && option.type === "selfOrder" && option.allowedTableIds?.includes(tableId)
    )
  }, [distOptions, tableId])

  return distOption as WithRef<SelfOrderOption> | undefined
}

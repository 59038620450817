import { useTableIdDistOption } from "hooks/firestore/simple/useTableIdDistOption"
import { useTableIdFromUrl } from "hooks/localstate/url/useTableIdFromUrl"

export default function TableId() {
  const tableIdFromUrl = useTableIdFromUrl()
  const tableIdDistOption = useTableIdDistOption()

  return (
    !!tableIdFromUrl &&
    !tableIdDistOption?.hideTableId && (
      <p className="tw-text-center tw-m-0 tw-flex tw-items-center tw-justify-center tw-gap-x-1 tw-font-bold tw-bg-white tw-px-4 tw-py-2 tw-rounded-xl tw-text-black">
        Tisch {tableIdFromUrl}
      </p>
    )
  )
}

import HeaderLogo from "components/orderPage/HeaderLogo"
import SwitchBetweenB2BAndNormalModeButton from "components/orderPage/SwitchBetweenB2BAndNormalModeButton"
import { useShopStatus } from "hooks/firestore/advanced/useShopStatus"
import { useShop } from "hooks/firestore/simple/useShop"
import { useOrderMode } from "hooks/misc/useOrderMode"
import dynamic from "next/dynamic"
import Link from "next/link"
import React, { useState } from "react"
import { Warning } from "styled-icons/fluentui-system-filled"
import MyOrdersButton from "./MyOrdersButton"
import Search from "./Search/Search"
import BasketButton from "./StickyBasket/BasketButton"
import TableId from "./TableId"

const NewsletterModal = dynamic(() => import("components/orderPage/NewsletterModal"), { ssr: false })

function ShopHeader() {
  const [isNewsletterView, setNewsletterView] = useState(false)
  const shop = useShop()
  const shopStatus = useShopStatus()

  const orderMode = useOrderMode()
  const isSelfOrder = orderMode === "selfOrder"

  return shop ? (
    <>
      <style jsx>{`
        .appHeader {
          width: 100%;
          display: flex;
          flex-direction: column;
          background: var(--gradient);
          border-radius: 0 0 0 30px;
          padding: calc(env(safe-area-inset-top, 20px) + 30px) var(--shopSpace) 74px;
          margin-bottom: -2.5rem;
          gap: 15px;
          color: var(--gradientText);
        }

        .shopTitleRow {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .shopLogoContainer {
          border-radius: 15px;
          padding: 2px;
          background: currentColor;
          display: flex;
        }
        .shopLogoContainer > :global(span) {
          border-radius: 13px;
        }

        .shopHeaderButton {
          margin-bottom: 0;
          padding: 0;
          border: none;
          background: none;
          outline: none;
          font-family: inherit;
          display: flex;
          align-items: center;
          flex-grow: 1;
          gap: 10px;
          cursor: pointer;
        }

        .shopHeaderButton:focus {
          outline: none;
        }

        .shopTitle {
          text-align: left;
          margin: 0;
          color: currentColor;
          font-size: 1.3rem;
        }

        .shopDescription {
          font-size: 1rem;
          line-height: 1.3;
          margin-bottom: 0;
        }
      `}</style>

      {isNewsletterView ? <NewsletterModal open={isNewsletterView} onClose={() => setNewsletterView(false)} /> : null}
      <div className="appHeader">
        <div className="shopTitleRow" data-nosnippet>
          <HeaderLogo shop={shop} />

          {isSelfOrder ? (
            <div className="shopHeaderButton">
              <h1 className="shopTitle">{shop.name}</h1>
            </div>
          ) : (
            <Link
              href={
                shop.domain
                  ? `https://${shop.domain}`
                  : `https://${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/${shop?.shorthand || shop?._ref.id}`
              }
              passHref
            >
              <div className="shopHeaderButton tw-cursor-pointer">
                <h1 className="shopTitle">{shop.name}</h1>
              </div>
            </Link>
          )}

          <div className="tw-flex tw-flex-row tw-items-stretch tw-gap-x-2">
            {isSelfOrder && <TableId />}

            <BasketButton useExpand={false} white />
          </div>
        </div>
        <SwitchBetweenB2BAndNormalModeButton />
        <MyOrdersButton />

        {shop.customShopNotification && <p className="shopDescription">{shop.customShopNotification}</p>}
        {!shop.hideSearchBarInStorefront && <Search />}
        {!shopStatus.loading
          ? (isSelfOrder ? !shopStatus.selfOrderPossible : !shopStatus.shopIsOpen) && (
              <p
                className="tw-text-center tw-m-0 tw-my-4 tw-text-xl tw-flex tw-items-center tw-justify-center tw-gap-x-1 tw-font-bold tw-bg-red-500 tw-p-4 tw-rounded"
                style={{ color: "var(--white)" }}
              >
                <Warning size="22" color="var(--white)" />{" "}
                {shop.customClosedShopMessage && shopStatus.shopClosedReason === "manual"
                  ? shop.customClosedShopMessage
                  : "Wir haben gerade geschlossen."}
              </p>
            )
          : null}
      </div>
    </>
  ) : null
}

export default React.memo(ShopHeader)
